<template>
	<div class="animated fadeIn">
		<b-card no-body class="shadow-lg rounded px-0 m-0" border-variant="primary">
			<b-card-body v-if="company_name">
				<b-form-group label="Company" label-for="connectedGroup" :label-cols="1">
					<b-form-input id="connectedGroup" v-model="company_name" disabled></b-form-input>
				</b-form-group>
			</b-card-body>
		</b-card>
		<div class="shadow-lg rounded">
			<b-overlay id="overlay-background" :show="isBusy" variant="secondary" opacity="0.8" blur="2px" rounded="sm">
				<MedsTable ref="table" :fields="fields" :items="devices" :customSlots="slots">
					<!-- latest device data -->
					<template v-slot:slot-temp1="data">
						{{ data.data.item.timestamp_set.length > 0 ? data.data.item.timestamp_set[0].measurement.temperature1 : "No Data" }}
					</template>
					<template v-slot:slot-temp2="data">
						{{ data.data.item.timestamp_set.length > 0 ? data.data.item.timestamp_set[0].measurement.temperature2 : "No Data" }}
					</template>
					<template v-slot:slot-temp3="data">
						{{ data.data.item.timestamp_set.length > 0 ? data.data.item.timestamp_set[0].measurement.temperature3 : "No Data" }}
					</template>
					<template v-slot:slot-timestamp="data">
						{{ data.data.item.timestamp_set.length > 0 ? formattedShortDateTime(data.data.item.timestamp_set[0].insert) : "No Data" }}
					</template>
					<template v-slot:slot-lock="data">
						{{ data.data.item.timestamp_set.length > 0 ? formatBinaryStatusValues("lock", data.data.item.timestamp_set[0].measurement.lock) : "No Data" }}
					</template>
					<template v-slot:slot-location="data">
						{{ data.data.item.timestamp_set.length > 0 ? combineLocation(data.data.item.timestamp_set[0].location) : "No Data" }}
					</template>
					<template v-slot:slot-orientation="data">
						{{ data.data.item.timestamp_set.length > 0 ? formatBinaryStatusValues("orientation", data.data.item.timestamp_set[0].measurement.orientation) : "No Data" }}
					</template>
					<template v-slot:actions="{ row }">
						<b-button-group >
							<b-button :disabled="!access_button" size="sm" variant="primary" @click="onClickCharts(row)"><i class="fa fa-bar-chart"></i></b-button>
							<b-button size="sm" variant="primary" @click="onBatteryCharged(row)"> <i class="fa fa-battery"></i></b-button>
						</b-button-group>
					</template>
				</MedsTable>
			</b-overlay>
		</div>
	</div>
</template>

<script>
import MedsTable from "../../components/MedsTable"
import { mapGetters, mapState, mapActions } from "vuex"
import ViewExtension from "../ViewExtension"

export default ViewExtension.extend({
	name: "ClientDashboard",
	props: ["context"],
	components: {
		MedsTable,
	},
	data() {
		return {
			fields: [
				{ key: "name", label: "Device" },
				{ key: "uuid", label: "ID" },
				{ key: "fw", label: "Firmware" },
				{ key: "type", label: "Type" },
				{ key: "timestamp", label: "Latest acquired data" },
				{ key: "temp1", label: "Temp1" },
				{ key: "temp2", label: "Temp2" },
				{ key: "temp3", label: "Temp3" },
				{ key: "lock", label: "Lock" },
				{ key: "location", label: "Long/Lang" },
				{ key: "orientation", label: "Orientation" },
				{ key: "alarm", label: "Alarm/ Last Alarm" },
				{ key: "next_charge", label: "Charge in (days)" },
				{ key: "actions", label: "Actions" },
			],
			slots: [{ key: "temp1" }, { key: "temp2" }, { key: "temp3" }, { key: "lock" }, { key: "location" }, { key: "orientation" }, { key: "timestamp" }, { key: "total_devices" }],

			devices: [],
			company_name: null,
			currentPage: 1,
			perPage: 10,
			sortedBy: "id",
			sortedDesc: true,
			totalRows: 0,
		}
	},
	computed: {
		...mapState("auth", {
			current: (state) => state.current,
		}),
		...mapState("common", {
			access_button: (state) => state.access_button,
		}),
		startIndex() {
			return (this.currentPage - 1) * this.perPage + 1
		},
		endIndex() {
			var end = this.totalRows
			if (this.perPage < this.totalRows) {
				end = this.perPage * this.currentPage
				if (end > this.totalRows) {
					end = this.totalRows
				}
			}
			return end
		},
		showPagination() {
			return this.perPage < this.totalRows
		},
	},
	watch: {
		context(val) {
			this.filterContext()
		},
	},
	methods: {
		...mapActions("meds2go/client", ["clientDashboard"]),
		...mapActions("meds2go/device", ["devicePartial"]),
		loadData() {
			this.loading()
			this.clientDashboard().then((results) => {
				this.devices = results
				if (results.length > 0) {
					this.company_name = results[0].group.companies[0].name
				}
			})
			this.stopLoading()
		},
		filterContext() {
			this.$refs.table.onRefreshTable()
		},
		combineLocation(data) {
			let long = data.longitude
			let lat = data.latitude
			return long.toString() + "/" + lat.toString()
		},
		onClickCharts(row) {
			if (row.item.hasOwnProperty("id")) {
				this.$router.push(`/clientdashboard/client/charts/${row.item.id}`)
			}
		},
		onBatteryCharged(row) {
			this.devicePartial({ id: row.item.id, consistency_token: row.item.consistency_token, battery_charged_on: new Date() }).then((results) => {
				this.loadData()
			})
		},
	},
	mounted() {
		this.loadData()
	},
})
</script>

<style>
b-tr.no-hover {
	background-color: #ffffff;
}
</style>
