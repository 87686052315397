<template>
    <div>
      <b-table 
        ref="table" 
        small 
        responsive 
        show-empty 
        hover
        :fields="fields" 
        :items="items"
        :busy.sync="busy"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortedDesc"
        :no-border-collapse="noBorderCollapse"
        head-variant="light"
        >
        <template v-for="slot in customSlots" v-slot:[`cell(${slot.key})`]="data">
          <slot :name="`slot-${slot.key}`" :data="data"></slot>
        </template>
        <template v-slot:cell(actions)="row">
          <slot name="actions" :row="row"></slot>
        </template>
        <template v-slot:row-details="row">
          <slot name="slot-row-details" :row="row"></slot>
        </template>
        <template v-slot:custom-foot="scope">
          <slot name="slot-custom-foot" :scope="scope"></slot>
        </template>
      </b-table>
    </div>
</template>

<script>
import ViewExtension from '../views/ViewExtension'

export default ViewExtension.extend({
  props: [
    'items',
    'fields',
    'customSlots',
    'busy',
    'currentPage',
    'perPage',
    'sortBy',
    'sortedDesc',
    'noBorderCollapse',
  ],
  methods: {
    onRefreshTable(){
      this.$refs.table.refresh()
    }
  },
})
</script>
